import Tinymce from "vue-tinymce-editor";

import {
	required,
	min,
	max
} from "vuelidate/lib/validators";

export default {
	name: "admin-formulario-checklist",
	components: {
		Tinymce
	},
	data() {
		return {
			arPerfis: [],
			arTiposDeclaracao: [],
			blLoading: true,
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			obForm: {
				id: 0,
				pergunta: '',
				documentos: '',
				id_checklist_tipos_declaracao: '',
				ids_perfil: [],
				obrigatorio: 0,
				informacao_adicional: ''
			},
			obOptions: {
				language_url: process.env.VUE_APP_ROOT_API + "../langs-tinymce/pt_BR.js",
				//images_upload_url: process.env.VUE_APP_ROOT_API + "post/uploadImagem",
				convert_urls: false
			}
		};
	},
	validations: {
		obForm: {
			pergunta: {
				required
			},
			documentos: {
				required
			},
			id_checklist_tipos_declaracao: {
				required
			},
			ids_perfil: {
				required
			},
		}
	},
	created() {
		this.obter(this.$route.params.id || 0);
	},
	methods: {
		editorDocumentosInit() {
			if (this.obForm.id != 0)
				this.$refs.refTinymceDocumentos.editor.setContent(this.obForm.documentos);
		},
		editorInformacaoInit() {
			if (this.obForm.id != 0)
				this.$refs.refTinymceInformacao.editor.setContent(this.obForm.informacao_adicional);
		},
		obter(id) {
			this.$root.$api.get("checklist/obter/" + id).then(response => {
				if (response.retorno.item) {
					this.$root.$utils.preencheFormularios(
						this.obForm,
						response.retorno.item
					);

					this.ids_perfil = [];

					response.retorno.item.perfis.forEach(perfil => {
						this.obForm.ids_perfil.push(perfil.id);
					});
				}

				this.arPerfis = response.retorno.perfis;
				this.arTiposDeclaracao = response.retorno.tipos_declaracao;

				this.blLoading = false;
			});
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		submit() {
			console.log(this.$v);

			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.blSalvando = true;

			this.$root.$api
				.post("checklist/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;

					this.$router.push({
						name: "admin-checklist"
					});
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("checklist/excluir/" + this.obForm.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-checklist"
					});
				});
		}
	}
};